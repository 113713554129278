<section id="breadcrumbs" class="breadcrumbs">
    <div class="container">

        <div class="d-flex justify-content-between align-items-center">
            <h2>Institutes</h2>
            <ol>
                <li><a routerLink="/">Home</a></li>
                <li>Institutes</li>
            </ol>
        </div>
    </div>
</section>

<section id="portfolio" class="portfolio">
    <div class="container table-responsive" *ngFor="let c of cl">
        <h2 class="text-center">{{ c.division }} Division</h2>
        <!-- <p>Set the width of the table, and the height of the table header row:</p> -->

        <table class="table table-bordered table-hover">
            <thead class="thead-light"> 
                <tr>
                    <th class="text-center" *ngFor="let head of thd" scope="col">{{head}} </th>
                    <!-- <th class="text-center" style="border: 1px solid black; height: 50px;" >Firstname</th>
                    <th class="text-center" style="border: 1px solid black; height: 50px;">Lastname</th>
                    <th class="text-center" style="border: 1px solid black; height: 50px;">Savings</th> -->
                </tr>
            </thead>
            <tbody>
                <tr mdbTableCol *ngFor="let institute of c.instituteList; let i = index">
                    <th class="text-center" scope="row">{{i+1}}</th>
                    <td class="text-center">{{institute.name}}</td>
                    <td class="text-center">{{institute.district}}</td>
                    <td class="text-center">{{institute.start}}</td>
                </tr>
            </tbody>
        </table>
        <br><br>
    </div>
</section>