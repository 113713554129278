   <!-- ======= Institute Section ======= -->
   <div class="container">
      <div class="section-title">
        <h2>Events</h2>
        <p>Science Workshops</p>
      </div>
    </div>
   <section id="event" class="event section-bg">
    <div class="container">
      <carousel
          [images]="images">
      </carousel>
    </div>
  </section>
  <!-- End Institute Section -->
