<!-- ======= Breadcrumbs ======= -->
<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h2>Contact Us</h2>
      <ol>
        <li><a routerLink="/">Home</a></li>
        <li>Contact</li>
      </ol>
    </div>
  </div>
</section><!-- End Breadcrumbs -->

<!-- ======= Contact Section ======= -->
<section id="contact" class="contact">
  <div class="container">
    <!-- Location Map -->
    <app-location-map></app-location-map>
    <!-- End Location Map -->

    <!-- <app-location-address></app-location-address> -->
    <!-- <div class="row mt-5"> -->
      
      <!-- Location Address -->
      <!-- <div class="col-lg-4">
        <app-location-address></app-location-address>
      </div> -->
      <!-- End Location Address -->

      <!-- Contact Us Form -->
      <!-- <div class="col-lg-8 mt-5 mt-lg-0">
        <app-email-form></app-email-form>
      </div> -->
    <!-- </div> -->
    <!-- End Contact Us Form -->
  </div>
</section><!-- End Contact Section -->