import { Component, OnInit } from '@angular/core';
import * as data from './join-us.json';
import { JoinUsService } from 'src/app/services/join-us.service';
import * as _ from 'underscore';

declare var $: any;

@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.css']
})
export class JoinUsComponent implements OnInit {

  join_us: any;
  // join_us: any = [];

  constructor(private joinUsService: JoinUsService) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.retrieveJoinUs();
  }

  retrieveJoinUs(){
    this.joinUsService.getData()
      .subscribe(
        data => {
          console.log(data['data']);
          this.join_us = data['data'];
        },
        error => {
          console.log(error);
          this.join_us  = (data as any).default;
        });
  }

}
