import { Component, OnInit } from '@angular/core';
import * as data from './event.json';
import { EventService } from 'src/app/services/event.service';
import { from } from 'rxjs';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {
  events = (data as any).default;

  navConfig = { 
    itemsPerPage: 4, 
    currentPage: 1
  };
  
  constructor(private eventService: EventService) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.retrieveEvent();
  }

  retrieveEvent(){
    this.eventService.getData()
      .subscribe(
        data => {
          console.log(data['data']);
          this.events = data['data'];
        },
        error => {
          console.log(error);
          this.events = (data as any).default;
        });
  }

  navigate(event) {
    window.scroll(0, 0);
    this.navConfig.currentPage = event;
  }


}
