import { Component, OnInit } from '@angular/core';
import * as data from './event.json';
import { EventService } from 'src/app/services/event.service';

import * as _ from 'underscore';

@Component({
  selector: 'app-home-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class HomeEventComponent implements OnInit {

  eventList: any = (data as any).default;
  images = [];

  constructor(private eventService: EventService) { }

  ngOnInit(): void {
    this.eventList = (data as any).default;
    this.images = this.eventList; _.pluck(this.eventList, "img");
  }

}
