   <!-- ======= Institute Section ======= -->
   <div class="container">
      <div class="section-title">
        <h2>Institutions</h2>
        <p>Where We Have Visited</p>
      </div>
    </div>
   <section id="institute" class="institute section-bg">
    <div class="container">
      <div class="row">
        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center" *ngFor="let institute of instituteList">
          <img [src]="institute.logo" class="img-fluid" alt="">
        </div>
      </div>
    </div>
  </section>
  <!-- End Institute Section -->
