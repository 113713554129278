<!-- ======= About Section ======= -->

<section id="slider">
  <div class="container">
    <br>
    <br>
    <!-- Slide 1 -->
    <div class="carousel-item active" [style.background-image]="'url('+ sliders.imgPath +')'">
      <div class="carousel-container">
        <div class="container">
          <h2 class="animate__animated animate__fadeInDown">{{ sliders.title }}</h2>
          <h4 style="color:white;" class="animate__animated animate__fadeInUp">{{ sliders.subtitle }}</h4>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End About Section -->
