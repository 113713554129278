<!-- ======= About Section ======= -->
<section id="about" class="about">
  <div class="container">
    <div class="row">
      <div class="col-md-5"></div>
      <div class="col-md-3">
        <!-- <h2>{{ about.title }}</h2> -->
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="row">
      <div class="col-md-12 pt-4 pt-lg-0">
        <p class="line-clamp">{{ about.description }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <button routerLink="/about"  type="button" class="btn btn-outline-secondary">Read More</button>
      </div>
    </div>
  </div>
</section>
<!-- End About Section -->