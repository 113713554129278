import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_SERVER } from './url.const';

const baseUrl = BASE_SERVER + 'objective';

@Injectable({
  providedIn: 'root'
})
export class ObjectiveService {

  constructor(private http: HttpClient) { }

  getData() {
    let headers = new HttpHeaders();
    headers = headers
    .set('Content-Type', 'application/json; charset=utf-8')
    .set('Accept', 'application/json; charset=utf-8')
    .set('User-Agent', 'CONTENT-ENGINE')
    .set('MAC', 'FF:FF:FF:FF:FF:FF');
    
    return this.http.get(baseUrl, { headers: headers });
  }
}
