import { Component, OnInit } from '@angular/core';
import * as data from './about.json';
import { AboutUsService } from 'src/app/services/about-us.service'
import * as _ from 'underscore';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  // about: any = (data as any).default;
  about: any;

  constructor(private aboutUsService: AboutUsService) { }

  ngOnInit(): void {
    this.retrieveAboutUs();
  }

  retrieveAboutUs(){
    this.aboutUsService.getData()
      .subscribe(
        data => {
          console.log(data['data']);
          this.about = data['data'];
          this.about = _.first(_.where(this.about, {title: 'HOME-ABOUT'}));
        },
        error => {
          console.log(error);
          this.about  = (data as any).default;
          this.about = _.first(this.about);
        });
  }

}
