import { Component, OnInit } from '@angular/core';
import * as data from './team.json';
import { TeamService } from 'src/app/services/team.service'

@Component({
  selector: 'app-team-member',
  templateUrl: './team-member.component.html',
  styleUrls: ['./team-member.component.css']
})
export class TeamMemberComponent implements OnInit {
  team: any = (data as any).default;
  // team: any = [];

  constructor(private teamService: TeamService) { }

  ngOnInit(): void {
    this.retrieveTeam();
  }

  retrieveTeam(){
    this.teamService.getData()
      .subscribe(
        data => {
          console.log(data['data']);
          this.team = data['data'];
        },
        error => {
          console.log(error);
          this.team = (data as any).default;
        });
  }

}
