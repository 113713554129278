import { Component, OnInit } from '@angular/core';
import * as data from './services.json';
import { OurServiceService } from 'src/app/services/our-service.service';

import { from } from 'rxjs';
@Component({
  selector: 'app-home-service',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  services: any = (data as any).default;
  constructor(private ourServiceService: OurServiceService) { }

  ngOnInit(): void {
    this.retrieveHomeService();
  }

  retrieveHomeService(){
    this.ourServiceService.getData()
      .subscribe(
        data => {
          console.log(data['data']);
          this.services = data['data'].slice(0, 4);
        },
        error => {
          console.log(error);
          this.services = (data as any).default;
        });
  }

}
