import { Component, OnInit } from '@angular/core';
import * as data from './objective.json';
import { ObjectiveService } from 'src/app/services/objective.service'

@Component({
  selector: 'app-objective',
  templateUrl: './objective.component.html',
  styleUrls: ['./objective.component.css']
})
export class ObjectiveComponent implements OnInit {

  objectives: any = (data as any).default;

  constructor(private objectiveService: ObjectiveService) { }

  ngOnInit(): void {
    this.retrieveObjective();
  }

  retrieveObjective(){
    this.objectiveService.getData()
      .subscribe(
        data => {
          console.log(data['data']);
          this.objectives = data['data'];
        },
        error => {
          console.log(error);
          this.objectives = (data as any).default;
        });
  }

}
