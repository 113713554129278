<!-- ======= Header ======= -->
<header id="header" class="fixed-top ">
    <div class="container align-items-center">
        <nav class="navbar navbar-expand-md navbar-light">
            <a routerLink="/" class="logo"><img [src]="logo" alt="" class="img-fluid"></a>
            <button id="toggle-button" type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                <span class="navbar-toggler-icon"></span>
            </button>
    
            <div class="collapse navbar-collapse" id="navbarCollapse" style="margin-left: 50px;">
                <div class="navbar-nav">
                    <a routerLink="/" class="nav-item nav-link" (click)="menuItemClick()">Home</a>
                    <a routerLink="/about" class="nav-item nav-link" (click)="menuItemClick()">About Us</a>
                    <a routerLink="/service" class="nav-item nav-link"  (click)="menuItemClick()">Services</a>
                    <!-- <a routerLink="/institute" class="nav-item nav-link"  (click)="menuItemClick()">Institutes</a> -->
                    <a routerLink="/event" class="nav-item nav-link"  (click)="menuItemClick()">Events</a>
                    <a routerLink="/team" class="nav-item nav-link"  (click)="menuItemClick()">Team</a>
                    <a routerLink="/join" class="nav-item nav-link"  (click)="menuItemClick()">Join Us</a>
                    <a routerLink="/contact" class="nav-item nav-link"  (click)="menuItemClick()">Contact Us</a>
                </div>
                <div class="navbar-nav ml-auto">
                    <!-- <a href="#" class="nav-item nav-link">Login</a> -->
                </div>
            </div>
        </nav>
    </div>
</header>
<!-- End Header -->