<!-- <app-header></app-header> -->

<!-- <main id="main"> -->
<!-- ======= Breadcrumbs ======= -->
<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">

    <div class="d-flex justify-content-between align-items-center">
      <h2>About Us</h2>
      <ol>
        <li><a routerLink="/">Home</a></li>
        <li>About</li>
      </ol>
    </div>

  </div>
</section><!-- End Breadcrumbs -->

<!-- ======= About Section ======= -->

<section id="about" class="about">
  <div class="container">

    <div class="row">
      <div class="col-md-5"></div>
      <div class="col-md-3">
        <!-- <h2>{{ about_us.title }}</h2> -->
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="row">
      <div class="col-md-12 pt-4 pt-lg-0">
        <p style="white-space: pre-wrap">{{ about_us.description }}</p>
      </div>
    </div>
  </div>
</section>


<!-- ======= Objective Section ======= -->
<app-objective></app-objective>
<!-- End Objective Section -->

<div class="text-center">
  <youtube-player width="70%" height="500px" videoId="2_o3WN-8Z3E"></youtube-player>
</div>
