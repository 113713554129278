import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { NgxPaginationModule } from 'ngx-pagination';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { TeamComponent } from './components/team/team.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { JoinUsComponent } from './components/join-us/join-us.component';
import { ServiceDetailsComponent } from './components/service/service.component';
import { ContactComponent } from './components/contact/contact.component';

import { SliderSectionComponent } from './components/home/slider/slider.component';
import { AboutComponent } from './components/home/about/about.component';
import { HomeInstituteComponent } from './components/home/institute/institute.component';
import { ServicesComponent } from './components/home/services/services.component';

import { LocationMapComponent } from './components/contact/location-map/location-map.component';
import { LocationAddressComponent } from './components/contact/location-address/location-address.component';
import { EmailFormComponent } from './components/contact/email-form/email-form.component';

import { TeamMemberComponent } from './components/team/team-member/team-member.component';

import { InstituteComponent } from './components/institute/institute.component';
import { ObjectiveComponent } from './components/objective/objective.component';
import { HomeTeamComponent } from './components/home/home-team/home-team.component';
import { HomeEventComponent } from './components/home/event/event.component';
import { LocationAddressDarkComponent } from './components/footer/location-address-dark/location-address-dark.component';
import { EventComponent } from './components/event/event.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    TeamComponent,
    AboutUsComponent,
    JoinUsComponent,
    ServiceDetailsComponent,
    ContactComponent,
    SliderSectionComponent,
    AboutComponent,
    HomeInstituteComponent,
    ServicesComponent,
    EventComponent,
    HomeEventComponent,
    LocationMapComponent,
    LocationAddressComponent,
    EmailFormComponent,
    TeamMemberComponent,
    InstituteComponent,
    ObjectiveComponent,
    HomeTeamComponent,
    LocationAddressDarkComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    YouTubePlayerModule,
    NgxPaginationModule,
    IvyCarouselModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC4-AlqZ_uASUqirFhMcMGWkU_Pma_KS2A'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
