import { Component, OnInit } from '@angular/core';
import * as data from './institute.json';
import { InstituteService } from 'src/app/services/institute.service'

@Component({
  selector: 'app-home-institute',
  templateUrl: './institute.component.html',
  styleUrls: ['./institute.component.css']
})
export class HomeInstituteComponent implements OnInit {

  instituteList: any = (data as any).default;

  constructor(private instituteService: InstituteService) { }

  ngOnInit(): void {
    this.instituteList = (data as any).default;
  }

}
