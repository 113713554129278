import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ContactService } from 'src/app/services/contact.service'
import * as _ from 'underscore';

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.css']
})
export class LocationMapComponent implements OnInit {

  lat = 23.7413964;

  long = 90.3863314;
  
  constructor(private contactService: ContactService, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {  }

}
