<!-- ======= Slider Section ======= -->
<app-slider></app-slider>
<!-- End Slider -->
<main id="main">
    <!-- ======= About Section ======= -->
    <app-about></app-about>
    <!-- End About Section -->

    <!-- ======= Objective Section ======= -->
    <app-objective></app-objective>
    <!-- End Objective Section -->

    <!-- ======= Services Section ======= -->
    <app-home-service></app-home-service>
    <!-- End Services Section -->

    <!-- ======= Event Section ======= -->
    <app-home-event></app-home-event>
    <!-- End Event Section -->

    <!-- ======= Institute Section ======= -->
    <!-- <app-home-institute></app-home-institute> -->
    <!-- End Institute Section -->

    <!-- ======= Team Section ======= -->
    <app-home-team></app-home-team>
    <!-- End Team Section -->

    <div class="text-center">
        <youtube-player width="70%" height="500px" videoId="GZ2Re-SWBLQ"></youtube-player>
    </div>
</main>