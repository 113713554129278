<!-- ======= Breadcrumbs ======= -->
<section id="breadcrumbs" class="breadcrumbs">
    <div class="container">

        <div class="d-flex justify-content-between align-items-center">
            <h2>Services</h2>
            <ol>
                <li><a routerLink="/">Home</a></li>
                <li>Services</li>
            </ol>
        </div>

    </div>
</section><!-- End Breadcrumbs -->


<!-- <app-all-services></app-all-services> -->

<!-- ======= Services Section ======= -->
<section id="services" class="services">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mt-4 mt-md-0" *ngFor="let service of services">
          <div class="icon-box" >
            <i class="icofont-{{service.icon}}"></i>
            <h4><a>{{ service.title }}</a></h4>
            <p>{{ service.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Services Section -->
  
  

<div class="text-center">
    <youtube-player width="70%" height="500px" videoId="SKIeX3qYl_E"></youtube-player>
</div>
  