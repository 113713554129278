<!-- ======= Services Section ======= -->
<section id="services" class="services">
  <div class="container">
    <div class="section-title">
      <h2>Service</h2>
      <p>The Services We Offer</p>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-6 mt-4 mt-md-0" *ngFor="let service of services">
        <div class="icon-box" >
          <i class="icofont-{{service.icon}}"></i>
          <h4><a>{{ service.title }}</a></h4>
          <!-- <p>{{ service.description }}</p> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <button routerLink="/service" type="button" class="btn btn-outline-secondary">Read More</button>
      </div>
    </div>
  </div>
  
<!-- End Services Section -->
<!-- End Services Section -->