import { Component, OnInit } from '@angular/core';
import * as data from './team.json';
import { TeamService } from 'src/app/services/team.service'
import * as _ from 'underscore';

@Component({
  selector: 'app-home-team',
  templateUrl: './home-team.component.html',
  styleUrls: ['./home-team.component.css']
})
export class HomeTeamComponent implements OnInit {

  hteam: any = (data as any).default;

  constructor(private teamService: TeamService) { }

  ngOnInit(): void {
    this.retrieveTeam();
  }

  retrieveTeam(){
    this.teamService.getData()
      .subscribe(
        data => {
          console.log(data['data']);
          this.hteam = _.first(data['data'])['memberList'];
          console.log(this.hteam);
        },
        error => {
          console.log(error);
          this.hteam = (data as any).default;
        });
  }
}
