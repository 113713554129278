<section id="breadcrumbs" class="breadcrumbs">
    <div class="container">

        <div class="d-flex justify-content-between align-items-center">
            <h2>Events</h2>
            <ol>
                <li><a routerLink="/">Home</a></li>
                <li>Events</li>
            </ol>
        </div>
    </div>
</section>

<!-- ======= Events Section ======= -->
<section id="services" class="services">
    <div class="container">
      <div style="display: flex; justify-content: flex-end">
        <pagination-controls (pageChange)="navigate($event)"> </pagination-controls>
      </div>
      
      <div class="row">
        <div class="col-md-12 mt-4 mt-md-0" *ngFor="let event of events | paginate: navConfig">
          <div class="icon-box row">
            <div class="col-md-4 mt-4 mt-md-0"><img src="{{event.image}}"/></div>
            <div class="col-md-8 mt-4 mt-md-0">
              <h2>{{event.title}}</h2>
              <p>{{event.description}}</p>
            </div>              
          </div>
        </div>
      </div>

      <div style="display: flex; justify-content: flex-end">
        <pagination-controls (pageChange)="navigate($event)"> </pagination-controls>
      </div>
    </div>
  </section>
  <!-- End Events Section -->