<!-- <app-header></app-header> -->

<!-- <main id="main"> -->
<!-- ======= Breadcrumbs ======= -->
<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">

    <div class="d-flex justify-content-between align-items-center">
      <h2>Join Us</h2>
      <ol>
        <li><a routerLink="/">Home</a></li>
        <li>Join Us</li>
      </ol>
    </div>

  </div>
</section><!-- End Breadcrumbs -->

<!-- ======= Join Us Section ======= -->

<section id="join" class="join">
  <div class="container">
    <div class="row"><img style="width: 100%;" src="assets/img/slide/icp00.jpg"></div>
    <div class="accordion" id="join-us">
      <div class="card" *ngFor="let join_item of join_us">
        <div class="card-header" id="accordion-header-{{join_item.no}}">
          <h2>
            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" attr.data-target="#accordion-body-{{join_item.no}}" aria-expanded="false" attr.aria-controls="accordion-body-{{join_item.no}}">
              <strong>{{join_item.title}}</strong>
            </button>
          </h2>
        </div>
        <div id="accordion-body-{{join_item.no}}" class="collapse" attr.aria-labelledby="accordion-header-{{join_item.no}}" data-parent="#join-us">
          <div class="card-body">
            {{join_item.description}}
          </div>
        </div>
      </div>
    </div>    
  </div>
</section>
