<section id="features" class="features">
  <div class="container">
    <div class="section-title">
      <h2>Objective</h2>
      <p>Our Objectives</p>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6 mt-4 mt-md-0" *ngFor="let objective of objectives">
          <div class="icon-box" style="padding: 3%;">
            <h4 style="padding: 1%;">
              <i class="icofont-{{objective.icon}}"></i>
              {{objective.title}}
            </h4>
            <p>{{objective.description}}</p>            
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ======= Objective Section ======= -->
<!-- End Objective Section -->