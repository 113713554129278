import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { TeamComponent } from './components/team/team.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { JoinUsComponent } from './components/join-us/join-us.component';
import { ServiceDetailsComponent } from './components/service/service.component';
import { ContactComponent } from './components/contact/contact.component';
import { InstituteComponent } from './components/institute/institute.component';
import { EventComponent } from './components/event/event.component';



const routes: Routes = [
  { path: '', pathMatch: 'full', component: HomeComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'team', component: TeamComponent },
  { path: 'service', component: ServiceDetailsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'institute', component: InstituteComponent },
  { path: 'join', component: JoinUsComponent },
  { path: 'event', component: EventComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
