import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  logo = "assets/img/logo.png"
  collapsed = true;
  constructor() { }

  ngOnInit(): void {
    this.menuItemClick();
  }

  menuItemClick() {
    $("#navbarCollapse").removeClass( "navbar-collapse collapse show" ).addClass( "navbar-collapse collapse" );
  }

}
