<!-- ======= Team Section ======= -->

<section id="team" class="team ">
    <div class="container">
        <div *ngFor="let t of team;">
            <div class="section-title">
                <h2>Team</h2>
                <p>{{ t.category }}</p>
            </div>
            <div class="row">
                <div class="col-lg-6 mt-4" *ngFor="let tmember of t.memberList;">
                    <div class="member d-flex align-items-start">
                        <div class="pic"><img [src]="tmember.photo" class="img-fluid" alt=""></div>
                        <div class="member-info">
                            <h4>{{ tmember.name }}</h4>
                            <span>{{ tmember.designation }}</span>
                            <div class="social">
                                <a><i class="ri-twitter-fill"></i></a>
                                <a><i class="ri-facebook-fill"></i></a>
                                <a><i class="ri-instagram-fill"></i></a>
                                <a> <i class="ri-linkedin-box-fill"></i> </a>
                            </div>
                        </div>
                    </div>
                </div>        

            </div>
            <div><br><br></div>
        </div>

    </div>
</section><!-- End Team Section -->