<div class="info row mt-3">
  <div class="address col-xs-12 col-md-6 col-lg-4 mt-3">
    <i class="icofont-google-map"></i>
    <h4>Location:</h4>
    <p>{{location.locationInfo.address}}</p>
  </div>

  <div class="email col-xs-12 col-md-6 col-lg-4">
    <i class="icofont-envelope"></i>
    <h4>Email:</h4>
    <p>{{location.contactInfo.email}}</p>
  </div>

  <div class="phone col-xs-12 col-md-6 col-lg-4">
    <i class="icofont-phone"></i>
    <h4>Call:</h4>
    <p>{{location.contactInfo.contact_no}}</p>
  </div>

</div>