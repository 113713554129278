<!-- ======= Footer ======= -->
<footer id="footer">
  <div class="footer-top">
    <div class="container">
      <app-location-address-dark></app-location-address-dark>
    </div>
  </div>

  <div class="container">
    <div class="copyright">
      &copy; Copyright <strong><span>শিক্ষা দেশের জন্য</span></strong>. All Rights Reserved
    </div>
    <div class="credits">
      <!-- All the links in the footer should remain intact. -->
      <!-- You can delete the links only if you purchased the pro version. -->
      <!-- Licensing information: https://bootstrapmade.com/license/ -->
      <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/sailor-free-bootstrap-theme/ -->
      Designed by <a href="https://www.binirmaan.com/" target="_blank">Binirmaan Technologies</a>
    </div>
  </div>
</footer><!-- End Footer -->

<a (click)="goToTop()" class="back-to-top"><i class="icofont-simple-up"></i></a>