import { Component, OnInit } from '@angular/core';
import { OurServiceService } from 'src/app/services/our-service.service'
// import { HttpClient } from "@angular/common/http";
import * as data from './services.json';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceDetailsComponent implements OnInit {

  // icon = "briefcase";
  services: any = (data as any).default;
  // ser: any = [];

  constructor(private ourServiceService: OurServiceService) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.retrieveServiceAll();
  }

  retrieveServiceAll(){
    this.ourServiceService.getData()
      .subscribe(
        data => {
          console.log(data['data']);
          this.services = data['data'];
        },
        error => {
          console.log(error);
          this.services = (data as any).default;
        });
  }

}
