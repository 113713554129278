import { Component, OnInit } from '@angular/core';
import * as data from './location.json';
import { ContactService } from 'src/app/services/contact.service';
import * as _ from 'underscore';


@Component({
  selector: 'app-location-address-dark',
  templateUrl: './location-address-dark.component.html',
  styleUrls: ['./location-address-dark.component.css']
})
export class LocationAddressDarkComponent implements OnInit {

  location: any = (data as any).default;

  constructor(private contactService: ContactService) { }

  ngOnInit(): void {
    this.retrieveContact();
  }

  retrieveContact(){
    this.contactService.getData()
      .subscribe(
        data => {
          console.log(data['data']);
          this.location = data['data'];
          this.location = _.first(this.location);
        },
        error => {
          console.log(error);
          this.location = (data as any).default;
          this.location = _.first(this.location);
        });
  }
}
