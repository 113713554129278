import { Component, OnInit } from '@angular/core';
import * as data from './about_us.json';
import { AboutUsService } from 'src/app/services/about-us.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  about_us: any;
  // about_us: any = [];

  constructor(private aboutUsService: AboutUsService) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.retrieveAboutUs();
  }

  retrieveAboutUs(){
    this.aboutUsService.getData()
      .subscribe(
        data => {
          console.log(data['data']);
          this.about_us = data['data'];
          this.about_us = _.first(_.where(this.about_us, {title: 'ABOUT-US'}));
        },
        error => {
          debugger;
          console.log(error);
          this.about_us  = (data as any).default;
          this.about_us = _.last(this.about_us);
        });
  }

}
