import { Component, OnInit } from '@angular/core';
import * as data from './slider.json';
import { SliderService } from 'src/app/services/slider.service';
import { from } from 'rxjs';
import * as _ from 'underscore';


@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderSectionComponent implements OnInit {

  sliders: any = (data as any).default;

  constructor(private sliderService: SliderService) { }

  ngOnInit(): void {
    this.sliders = _.first(this.sliders);
    this.retrieveSlider();
  }

  retrieveSlider(){
    this.sliderService.getData()
      .subscribe(
        data => {
          console.log(data['data']);
          this.sliders = data['data'];
          this.sliders = _.first(this.sliders);
        },
        error => {
          console.log(error);
          this.sliders = (data as any).default;
          this.sliders = _.first(this.sliders);
        });
  }


}
