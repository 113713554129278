import { Component, OnInit } from '@angular/core';
import * as data from './institute.json';
import { InstituteService } from 'src/app/services/institute.service'
import { from } from 'rxjs';

@Component({
  selector: 'app-institute',
  templateUrl: './institute.component.html',
  styleUrls: ['./institute.component.css']
})
export class InstituteComponent implements OnInit {

  cl: any = (data as any).default;
  thd = ['SL', 'NAME', 'DISTRICT', 'STARTING FROM'];
  
  constructor(private instituteService: InstituteService) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.retrieveInstitute();
  }

  retrieveInstitute(){
    this.instituteService.getData()
      .subscribe(
        data => {
          console.log(data['data']);
          this.cl = data['data'];
        },
        error => {
          console.log(error);
          this.cl = (data as any).default;
        });
  }


}
