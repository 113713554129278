<div class="info row">
    <div class="address col-xs-12 col-md-6">        
        <h3><i class="icofont-google-map"></i> Location:</h3>
        <p>{{location.locationInfo.address}}</p>
    </div>

    <div class="email col-xs-12 col-md-6 col-lg-3">
        <h3><i class="icofont-envelope"></i> Email:</h3>
        <p>{{location.contactInfo.email}}</p>
    </div>

    <div class="phone col-xs-12 col-md-6 col-lg-3">
        <h3><i class="icofont-phone"></i> Call:</h3>
        <p>{{location.contactInfo.contact_no}}</p>
    </div>

</div>